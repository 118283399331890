
import { useEffect } from "react";

const usePrefetchCardImages = (cardPath) => {

    useEffect(() => {
        const suits = ['S', 'C', 'D', 'H'];
        const strengths = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A'];

        const otherCards = ['0J', '1J', 'RED_BACK', 'BLUE_BACK']
        for(let suitIndex = 0; suitIndex < suits.length; suitIndex++){
            for(let strengthIndex = 0; strengthIndex < strengths.length; strengthIndex++){
                const cardSrc = cardPath + strengths[strengthIndex] + suits[suitIndex] + '.svg';
                const img = new Image();
                img.src = cardSrc;
            }
        }
        for(let otherIndex = 0; otherIndex < otherCards.length; otherIndex++){
            const cardSrc = cardPath + otherCards[otherIndex] + '.svg';
            const img = new Image();
            img.src = cardSrc;
        }
    }, [cardPath]);
};

export default usePrefetchCardImages;

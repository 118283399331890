import './App.css';
import GameClient from './components/GameClient';
import { useEffect, useState } from 'react';
import { Config } from './Config';
import NameForm from './components/NameForm';
import * as io from "socket.io-client";
import DialogMessage from './components/DialogMessage';
import usePrefetchCardImages from './hooks/usePrefetchCardImages';

function App() {

  const [connected, setConnected] = useState(null);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState(null);

  const [stats, setStats] = useState(null)

  useEffect(() => {
    const socket = io(Config.API_ENDPOINT, {
      autoConnect: true
    });
    socket.on('connect', () => {
      console.log('Connection established!');
      setSocket(socket);
      socket.emit('checkCredentials', window.localStorage.getItem('gameId'), window.localStorage.getItem('playerId'));
      socket.on('validCredentials', () => {
        setConnected(true);
      })
      socket.on('gameEnded', () => {setConnected(false)});
      socket.on("disconnect", (reason) => {
        console.log(reason);
        setConnected(false);
      });
      socket.on('sendStats', (s) => {
        setStats(s)
      });
      socket.on('message', (message) => {
        let formattedMessage = {};
        formattedMessage.title = message.title;
        formattedMessage.body = message.body;
        if(message.options) {
          formattedMessage.options = [];
          for(let i = 0; i < message.options.length; i++){
            formattedMessage.options.push({option: message.options[i].option, func: () => {
                socket.emit(message.options[i].event_name);
            }})
          }
        }
        setMessage(formattedMessage)
      });
    })
    return () => {
      socket.disconnect();
    }
  }, []);

  usePrefetchCardImages(Config.CARD_IMAGE_PATH);

  const onCreate = (name) => {
    socket.emit('create', name);
    // setConnected(true);
  }

  const onJoin = (name, gameCode) => {
    socket.emit('join', name, gameCode);
    // setConnected(true);
  }

  return (
    <div className="App">
      <div className="topBanner">

      </div>
        {!connected && (
          <NameForm onCreate={onCreate} onJoin={onJoin} stats={stats}/>
        )}
        {
          connected &&
          <GameClient socket={socket} setMessage={setMessage}/>
        }
        {
          message &&
          <DialogMessage message={message} onAcknowledge={() => {setMessage(null);}} socket={socket}/>
        }
    </div>
  );
}

export default App;

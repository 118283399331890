
const suits = ['♠', '♣', '♦', '♥', 'X'];
const suitsAPI = ['S', 'C', 'D', 'H', 'J'];

const makeWagerLabel = (tricks, suit) => {
    if(suit !== undefined){
        let className = "wagerLabelSuitBlack";
        if(suit === 2 || suit === 3)
            className = "wagerLabelSuitRed"
        if(suit === 4)
            className = "";

        return (
            <>{tricks}<span className={className}>{suits[suit]}</span></>
        );
    }
    else
        return (
            <>{tricks}</>
        );
}

export const Wagers = ({ state, onSelect, showMessage }) => {

    // const tricks = [6, 7, 8, 9, 10];

    const options = [];
    for (let tricks = 6; tricks <= 10; tricks++) {
        for (let suit = 0; suit < suits.length; suit++) {
            const value = suit * 20 + 40 + (tricks - 6) * 100;
            const apiTricks = tricks === 10 ? 'T' : '' + tricks;
            options.push({
                wager: makeWagerLabel(tricks, suit),
                value,
                apiValue: apiTricks + suitsAPI[suit]
            })
        }
    }

    for (let suit = 0; suit < suits.length; suit++) {
        options.push({
            wager: makeWagerLabel(10, suit),
            value: 1000,
            apiValue: 'J' + suitsAPI[suit]
        })
    }

    options.push({ wager: makeWagerLabel(0, undefined), value: 250, apiValue: '1J' });
    options.push({ wager: makeWagerLabel(0, undefined), value: 500, apiValue: '2J' });
    options.push({ wager: makeWagerLabel(0, undefined), value: 1000, apiValue: '3J' });
    options.push({ wager: 'Passe', value: 0, apiValue: '0J' });

    const currWager = state ? state.current_wager_value : "0J";
   
    const onClickWager = (wager) => {
        if(wager.apiValue === '2J' || wager.apiValue === '3J') {
            // big nullo, warn no card exchange
            showMessage({
                title: 'Confirmer votre Gageure',
                body: "Êtes-vous sûr de vouloir gager le Gros Nullo (pour " + wager.value + " points)? Vous n'échangerez pas de cartes.",
                options: [
                    {option: 'Annuler', func: () => {}},
                    {option: 'Oui', func: () => {onSelect(wager.apiValue)}},
                ]
            })
        } else if(wager.value > 420) {
            // ask to confirm
            showMessage({
                title: 'Confirmer votre Gageure',
                body: <span>Êtes-vous sûr de vouloir faire cette gageure: {wager.wager} (pour {wager.value} points) ?</span>,
                options: [
                    {option: 'Annuler', func: () => {}},
                    {option: 'Oui', func: () => {onSelect(wager.apiValue)}},
                ]
            })
        }
        else {
            onSelect(wager.apiValue);
        }
    }

    return (
        <div className="wagerPanel">
            <div className="wagerOptionWrapper">
                <div className="wagerOptions">

                    {
                        options.map((wager, index) => {
                            if (wager.value > currWager || wager.value === 0) {
                                return (
                                    <div className="wagerOption" key={index} onClick={() => { onClickWager(wager) }}>
                                        <span className="wagerLabel">{wager.wager}</span>
                                        <span className="wagerValue"> ({wager.value})</span>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="wagerOption disabled" key={index}>
                                        <span className="wagerLabel">{wager.wager}</span>
                                        <span className="wagerValue"> ({wager.value})</span>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
}
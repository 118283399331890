const GameCode = ({state, socket}) => {

    const isHost = state ? state.host_id === state.your_id && !state.is_spectator : false;
    const isSpectator = state && state.is_spectator
    const seeAll = isSpectator && state.specatator_others_hidden

    const handleEndGame = () => {
        socket.emit('endGame');
    }

    const handleHideCards = () => {
        socket.emit('spectateHideOthers', !seeAll);
    }

    return (
        <div className="gameCodeBox">
            <div className="gameCodeTitle"><span>Code du Jeu:</span></div>
            <div className="gameCode">
                <span><strong>{state ? state.game_id : ''}</strong></span>
            </div>
            {isHost && (
                <button onClick={handleEndGame}>Terminé la Partie</button>
            )}
            {isSpectator && (
                seeAll ?
                    <button onClick={handleHideCards}>Voir tous les jeux</button>
                :
                    <button onClick={handleHideCards}>Cacher les autres jeux</button>
            )}
        </div>
    );

}

export default GameCode;
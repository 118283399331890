import ScorePanel from './ScorePanel';

const ScoreCard = ({state}) => {

    const teams = {};
    if(state && state.players.length === 4){
        for(let i = 0; i < state.players.length; i++){
            const player = state.players[i];
            if(player.team_id in teams){
                teams[player.team_id].push(player);
            } else {
                teams[player.team_id] = [player];
            }
        }
    }

    return (
       <ScorePanel className="scoreCardLeft" title={"Scores"}>
           { Object.keys(teams) && 
               Object.keys(teams).map(teamId => {
                   const player1Name = teams[teamId][0].player_name;
                   const player2Name = teams[teamId][1].player_name;
                   const score = state.scores[teamId];
                   const matches = state.games_won[teamId];
                   return (
                        <div className="teamScore" key={teamId}>
                            <span className="playerNames">
                                <strong>{player1Name} & {player2Name}</strong><br/>
                            </span>
                            <span>Score: {score} </span> <br />
                            <span># Parties: {matches} </span> <br />
                        </div>
                   )
               })
           }
       </ScorePanel> 
    );
}

export default ScoreCard;
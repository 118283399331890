const Development = {
    API_ENDPOINT: 'http://192.168.1.8:5001',
    CARD_IMAGE_PATH: '/images/cards/'
};

const Production = {
    API_ENDPOINT: 'https://jeu500.com:5002',
    CARD_IMAGE_PATH: '/images/cards/'
}

export const Config = process.env.NODE_ENV === 'production' ? Production : Development;
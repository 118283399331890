import { useState } from "react";

const MAX_NAME_LENGTH = 16;

const NameForm = ({onCreate, onJoin, stats}) => {

    const [name, changeName] = useState("");
    const [joinScreen, setJoinScreen] = useState(false);
    const [gameCode, setGameCode] = useState("");

    const handleNameChange = (event) => {
        event.preventDefault();
        changeName(event.target.value.substring(0, MAX_NAME_LENGTH));
    }

    const handCodeChange = (event) => {
        event.preventDefault();
        setGameCode(event.target.value.toUpperCase());
        event.preventDefault();
    }

    const handleEnterForCode = (event) => {
        event.preventDefault();
        onJoin(name, gameCode);
    }

    return (
        <div className="center">

        <div className="nameFormBox">
                 <div className="logo">
                    {/* <span ><em>500</em></span> */}
                    <img alt="500" className="imgLogo" src="/images/bannerGlow250.png"></img>
                </div>
            { !joinScreen && (
                <>
               
                <form className="nameForm" onSubmit={(e) => {e.preventDefault();}}>
                    <label>
                        Votre nom:
                    <input 
                        required
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}}
                    >
                    </input>
                    </label>
                    <div className="buttons">
                        <button onClick={() => {onCreate(name)}}className="button createGame" disabled={!name}>Créer une Nouvelle Partie</button>
                        <button onClick={() => {setJoinScreen(true)}} className="button joinGame" disabled={!name}>Joindre une Partie </button>
                    </div>
                </form>
                </>
            )}
            {joinScreen && (
                <form className="codeForm" onSubmit={(e) => e.preventDefault()}>
                    <label>
                        Code du jeu:
                        <input 
                            required
                            type="text"
                            value={gameCode}
                            onChange={handCodeChange}
                            onKeyPress={(e)=>{e.key === 'Enter' && handleEnterForCode(e)}}
                        >
                        </input>
                    </label>
                    <div className="buttons">
                        <button onClick={() => {setJoinScreen(false)}} className="button cancelButton"> {'<'} Retourner </button>
                        <button onClick={() => {onJoin(name, gameCode)}} className="button joinGame" disabled={!gameCode}>Joindre</button>
                    </div>
                </form>
            )}
                <div className="attributions">
                    <span>Samuel Laberge, 2020</span>
                </div>
            </div>
                <div className="stats">
                    # parties jouées: {stats && stats.numGames !== undefined ? stats.numGames : '...'}
                </div>
        </div>
    )
}

export default NameForm;
import {useState} from 'react';

const ScorePanel = ({className, title, ...props}) => {

    const [expanded, setExpanded] = useState(true);

    return (
        <div className={className}>
            <div className="titleBar" onClick={() => setExpanded(!expanded)}>
                <span>{title}</span>
            </div>
            {expanded && (
                <div className="scores">
                    {props.children}
                </div>
            )}
        </div>
    );
}

export default ScorePanel;
import { useRef, useState } from 'react';
import '../styles/card.css';
import Card from './Card';
import useDimensions from '../hooks/useDimensions';
import {Config} from '../Config';

// card height / width
const HEIGHT_TO_WIDTH_RATIO = 1.4;

const CardCollection = ({cards, onPlayCard, onExchangeCards, highlightedCards,
                        onSwapCard, playerName, onSpectatePlayer, className, isNext, state, isSpectator}) => {


    const collectionRef = useRef();
    let isInteractive = false;
    if(state){
        isInteractive = state.current_player === state.your_id;
    }

    const [selectedCard, setSelectedCard] = useState(null);
    const [exchangeCards, setExchangeCards] = useState([]);

    const {width, height} = useDimensions(collectionRef);

    const overlap = 0.5;

    const cardWidthsUsed = cards.length - (overlap * (cards.length - 1));

    const widthPerCard = cards.length > 0 ? width / cardWidthsUsed : 0;
    const actualWidth = Math.min(widthPerCard, 1 / HEIGHT_TO_WIDTH_RATIO * height);
    const overlapFactor = actualWidth * overlap;

    const totalWidthUsed = cardWidthsUsed * actualWidth;
    const centeringOffset = (width - totalWidthUsed) / 2;

    const selectCard = (card) => {
        if(isInteractive){
            if(card === selectedCard)
                setSelectedCard(null);
            else
                setSelectedCard(card);
        }
    }

    const selectExchangeCard = (card) => {
      if(exchangeCards.includes(card)){
          setExchangeCards(exchangeCards.filter((c) => ( c !== card )));
      } else {
        if(exchangeCards.length < 6)
            setExchangeCards([...exchangeCards, card]);
      }
    }

    const playCard = () => {
        if(playing)
            onPlayCard(selectedCard);
        else if(swapping)
            onSwapCard(selectedCard);
        else if(exchanging) 
            onExchangeCards(exchangeCards);
        setSelectedCard(null);
        setExchangeCards([]);
    }
    let gameStage = "waiting";
    let buttonText = "Jouer cette carte";
    let cardFunction = selectCard;
    let playing = false;
    let exchanging = false;
    let swapping = false;
    if(state){
        gameStage = state.game_stage;
        playing = gameStage === "tricks";
        exchanging = gameStage === "exchanging";
        swapping = gameStage === "nullo";
        if(exchanging){
            buttonText = "Retourner ces cartes";
            cardFunction = selectExchangeCard;
        } else if(swapping){
            buttonText = "Échanger cette carte";
        }
    }

    isInteractive = isInteractive && (playing || exchanging || swapping);

   
    const showButton = isInteractive && (selectedCard || exchangeCards.length === 6);

    console.log(`isSpectator ${isSpectator}`)

    const nameClicked = () => {
        if(isSpectator) {
            console.log('name clicked')
            onSpectatePlayer()
        }
    }

    const nameClass = `nameWrapper ${isNext ? "nextPlayer " : ""} ${isSpectator ? "clickable" : ""}`
  
    return (
        <div className={className}>
            <div className="nameAndCards">
                { showButton && 
                    <button className="playCardButton" onClick={playCard}>{buttonText}</button> 
                }
                
                <div ref={collectionRef} className="cardCollection">
                    { playerName && (
                        <div className={nameClass} onClick={isSpectator ? nameClicked : undefined}>
                            <p className="playerName"><strong>{playerName}</strong></p> 
                        </div>
                        )
                    }
                    {cards.map((card, index) => {
                        const cardImg = Config.CARD_IMAGE_PATH + card + '.svg';
                        return (
                                <Card 
                                    cardImg={cardImg}
                                    width={Math.min(widthPerCard, height * (1 / HEIGHT_TO_WIDTH_RATIO))}
                                    height={height}
                                    key={index}
                                    leftShift={overlapFactor * index + centeringOffset}
                                    onClick={() => cardFunction(card)}
                                    isActive={isInteractive && (card === selectedCard || exchangeCards.includes(card))}
                                    interactive={isInteractive}
                                    highlighted={highlightedCards ? exchanging && highlightedCards.includes(card) : false}
                                />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default CardCollection;
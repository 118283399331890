import { useEffect, useState } from "react";
import CardCollection from './CardCollection';
import CenterCards from './CenterCards';
import {getCardsForOrderOffset, getHighlightedCards, getIdForOrderOffset, getMyDeck, getMyId, getMyName, getMyPlayer, getNameForOrderOffset, getPlayerForOrderOffset, playerIsNext} from '../api/StateUtilities';
import { Wagers } from "./Wagers";
import ScoreCard from "./ScoreCard";
import WagerCard from "./wagerCard";
import SuitChooser from "./SuitChooser";
import GameCode from './GameCode';

const GameClient = ({socket, setMessage}) => {

    // const [socket, setSocket] = useState();
    const [showWagers, setShowWagers] = useState(false);
    const [getJokerSuit, setGetJokerSuit] = useState(false);
    const [jokerChosen, setJokerChosen] = useState(null);
    // const [wagerInfo, setWagerInfo] = useState(null);
    const [gameState, setGameState] = useState(null);


    useEffect(() => {
        socket.on('sendState', (state) => {
            console.log(state);
            // if state.your_id !== undefined, then we are a player
            // and should be placed back in the game whenever we quit
            if(!state.is_spectator){
                window.localStorage.setItem("playerId", state.your_id);
                window.localStorage.setItem("gameId", state.game_id);
            }
            // otherwise, we're just a spectator
            setGameState(state);
            setShowWagers(false);
        });
        socket.on('requestWager', () => {
            // setWagerInfo(wagerState);
            setShowWagers(true);
        });
        // get some initial state
        socket.emit('requestState');

        // clean up listeners
        return () => {
            socket.removeAllListeners('sendState');
            socket.removeAllListeners('requestWager');
        };
    }, [socket]) 

    const onPlayCard = (card) => {
        // hacky: before we send card, check to see if it's
        // a joker and wager has no suit and first to play.
        // If so, we also need to
        // get the request suit for this joker and send that along too
        // e.g. 0J may become 0H if heart is requested
        if(gameState && gameState.center_cards.length === 0){
            // joker played first with no wager suit
            if(card.charAt(1) === "J" && gameState.current_wager.wager.charAt(1) === "J"){
                setGetJokerSuit(true);
                setJokerChosen(card);
                return;
            }
        }
        socket.emit('playCard', card);
    }

    const sendJoker = (suit) => {
        const newJoker = jokerChosen.charAt(0) + suit;
        socket.emit('playCard', newJoker);
        setGetJokerSuit(false);
        setJokerChosen(null);
    }

    const onSwapCard = (card) => {
        socket.emit('swapCard', card);
    }

    const onExchangeCards = (cards) => {
        socket.emit('exchangeCards', cards);
    }

    const onSelectWager = (wager) => {
        socket.emit('makeWager', wager);
    }

    const onSpectatePlayer = (playerId) => {
        socket.emit('spectateAs', playerId)
    }

    const isSpectator = gameState && gameState.is_spectator;

    return (
        <>
            { !isSpectator && (
                <CardCollection 
                    className="collectionWrapperBottom"
                    cards={getMyDeck(gameState)}
                    onPlayCard={onPlayCard}
                    onSwapCard={onSwapCard}
                    onExchangeCards={onExchangeCards}
                    playerName={getMyName(gameState)}
                    playerId={getMyId(gameState)}
                    isNext={playerIsNext(gameState, getMyPlayer(gameState)) && !getJokerSuit}
                    highlightedCards={getHighlightedCards(gameState)}
                    state={gameState}
                />
            )}
            {
                isSpectator && (
                <CardCollection 
                    className="collectionWrapperBottom"
                    cards={getCardsForOrderOffset(gameState, 0)} 
                    playerName={getNameForOrderOffset(gameState, 0)}
                    playerId={getMyId(gameState)}
                    onSpectatePlayer={() => onSpectatePlayer(getMyId(gameState))}
                    isNext={playerIsNext(gameState, getPlayerForOrderOffset(gameState, 0))}
                    isSpectator={isSpectator}
                />
                )
            }
           <CardCollection 
                className="collectionWrapperLeft"
                cards={getCardsForOrderOffset(gameState, 1)} 
                playerName={getNameForOrderOffset(gameState, 1)}
                onSpectatePlayer={() => onSpectatePlayer(getIdForOrderOffset(gameState, 1))}
                isNext={playerIsNext(gameState, getPlayerForOrderOffset(gameState, 1))}
                isSpectator={isSpectator}
            />
            <CardCollection 
                className="collectionWrapperTop"
                cards={getCardsForOrderOffset(gameState, 2)} 
                playerName={getNameForOrderOffset(gameState, 2)}
                playerId={getIdForOrderOffset(gameState, 2)}
                onSpectatePlayer={() => onSpectatePlayer(getIdForOrderOffset(gameState, 2))}
                isNext={playerIsNext(gameState, getPlayerForOrderOffset(gameState, 2))}
                isSpectator={isSpectator}
            />
            <CardCollection 
                className="collectionWrapperRight"
                cards={getCardsForOrderOffset(gameState, 3)} 
                playerName={getNameForOrderOffset(gameState, 3)}
                playerId={getIdForOrderOffset(gameState, 3)}
                onSpectatePlayer={() => onSpectatePlayer(getIdForOrderOffset(gameState, 3))}
                isNext={playerIsNext(gameState, getPlayerForOrderOffset(gameState, 3))}
                isSpectator={isSpectator}
            />
            <CenterCards 
                state={gameState}
            />
            { showWagers && (
                <Wagers 
                    state={gameState}
                    onSelect={onSelectWager}
                    showMessage={setMessage}
                />
            )}
            {
                getJokerSuit && (
                    <SuitChooser
                        onSelect={sendJoker}
                    />
                )
            }
            <ScoreCard state={gameState}/>
            <WagerCard state={gameState}/>
            <GameCode state={gameState} socket={socket}/>
            {
                isSpectator &&
                <div className="spectatorMark">
                    SPECTATEUR
                </div>
            }
            {
                (gameState && !isSpectator && gameState.num_spectators > 1) && (
                    <div className="spectatorCount">
                        {gameState.num_spectators} Spectateurs
                    </div>
                )
            }
            {
                (gameState && !isSpectator && gameState.num_spectators === 1) && (
                    <div className="spectatorCount">
                        {gameState.num_spectators} Spectateur
                    </div>
                )
            }
        </>
    );
}



export default GameClient;
export const getMyDeck = (state) => {
    if(state) {
        const my_id = state.your_id;
        for(let i = 0; i < state.hands.length; i++){
            if(state.hands[i].player_id === my_id)
                return state.hands[i].cards;
        }
    }
    return [];
}

export const getMyName = (state) => {
    if(state){
        return getMyPlayer(state).player_name;
    }
    return null;
}

export const getMyId = (state) => {
    if(state) {
        return state.your_id;
    }
    return null;
}

export const getIdForOrderOffset = (state, offset) => {
    if(state) {
        const p = getPlayerForOrderOffset(state, offset)
        if(p) {
            return p.player_id
        }
    }
    return null;
}

export const getHandForPlayer = (state, playerId) => {
    if(state) {
        for(let i = 0; i < state.hands.length; i++){
            if(state.hands[i].player_id === playerId)
                return state.hands[i];
        }
    }
    return [];
}

export const getPlayerForOrderOffset = (state, orderOffset) => {
    if(state) {
        const me = getMyPlayer(state);
        const absOrder = (me.order + orderOffset) % 4;
        for(let i = 0; i < state.players.length; i++){
            if(state.players[i].order === absOrder)
                return state.players[i];
        } 
    }
    return null;
}

export const getNameForOrderOffset = (state, orderOffset) => {
    if(state) {
        const player = getPlayerForOrderOffset(state, orderOffset);
        if(!player)
            return '';
        return player.player_name;
    }
    return null;
}

const CARD_BACK = "RED_BACK";
export const getCardsForOrderOffset = (state, orderOffset) => {
    if(state) {
        const p = getPlayerForOrderOffset(state, orderOffset);
        if(!p){
            return [];
        }
        const h = getHandForPlayer(state, p.player_id);
        if(!h){
            return [];
        }
        if(h.hidden){
            return Array(h.num_cards).fill(CARD_BACK);
        } else {
            return h.cards;
        }
    }
    return [];
}

export const getPlayer = (state, playerId) => {
    if(state) {
        for(let i = 0; i < state.players.length; i++){
            if(state.players[i].player_id === playerId)
                return state.players[i];
        }
    }
    return null;
}

export const getMyPlayer = (state) => {
    if(state) {
        return getPlayer(state, state.your_id);
    }
    return null;
}

export const playerIsNext = (state, player) => {
    if(state && player){
        return state.current_player === player.player_id && state.game_stage !== 'waiting';
    }
    return false;
}

export const getHighlightedCards = (state) => {
    if(state){
        const my_id = state.your_id;
        for(let i = 0; i < state.hands.length; i++){
            if(state.hands[i].player_id === my_id)
                return state.hands[i].highlighted_cards;
        }
    }
    return [];
}
import ScorePanel from './ScorePanel';

const WagerCard = ({state}) => {
    const showTricksWon = state ? 
        // show the number of tricks round while tricks are being played and 
        // at the end of the trick playing
        state.game_stage === 'tricks' || state.game_stage === 'end' 
        : false;

    const players = {};
    const wagers = {};
    const teams = {};
    if(state && state.players.length === 4){
        for(let i = 0; i < state.players.length; i++){
            players[state.players[i].player_id] = state.players[i].player_name;
        }
        for(let i = 0; i < state.wagers_made.length; i++){
            wagers[state.wagers_made[i].player_id] = prettifyWager(state.wagers_made[i].wager);
        }
        for(let i = 0; i < state.players.length; i++){
            const player = state.players[i];
            if(player.team_id in teams){
                teams[player.team_id].push(player);
            } else {
                teams[player.team_id] = [player];
            }
        }
    }
    return (
       <ScorePanel className="scoreCardRight" title="Mises">
           { !showTricksWon && (
               Object.keys(players).map(playerId => (
                   <div className="playerWager" key={playerId}>
                    <span><strong>{players[playerId]}</strong>: {wagers[playerId]}</span>
                   </div>
               ))
           )}
           {showTricksWon && (
               <>
               <div className="playerWager">
                   <span><strong>{ players[state.current_wager.player_id]}</strong> : {prettifyWager(state.current_wager.wager)}</span>
                   <span> ({state.current_wager_value})</span>
               </div>
                   { Object.keys(teams).map(teamId => {
                       const player1Name = teams[teamId][0].player_name;
                       const player2Name = teams[teamId][1].player_name;
                       const tricks = state.tricks[teamId];
                       return (
                            <div key={teamId} className="playerWager">
                                <span className="playerNames">
                                    <strong>{player1Name} & {player2Name}</strong><br/>
                                </span>
                                <span>Levées: {tricks} </span> <br />
                            </div>
                       )
                   })}
                </>
           )}
       </ScorePanel> 
    );
}

const prettifyWager = (wager) => {
    if(!wager)
        return '...';
    const c0 = wager.charAt(0);
    const c1 = wager.charAt(1);
    if(c0 === '0')
        return 'Passe'
    if(c0 === '1')
        return 'Petit Nullo'
    if(c0 === '2')
        return 'Gros Nullo'
    if(c0 === '3')
        return '1K Nullo'
    
    const suits = {
        'S': <span className="wagerLabelSuitBlack">♠</span>,
        'C': <span className="wagerLabelSuitBlack">♣</span>,
        'D': <span className="wagerLabelSuitRed">♦</span>,
        'H': <span className="wagerLabelSuitRed">♥</span>,
        'J':' sans atout'
    }
    if(c0 === 'T')
        return <>10 {suits[c1]}</>
    if(c0 === 'J')
        return <>Partie en {suits[c1]}</>
    return <>{c0} {suits[c1]} </>
}

export default WagerCard;
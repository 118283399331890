import '../styles/card.css'

const Card = ({cardImg, width, height, leftShift, onClick, isActive, interactive, highlighted}) => {

    const style = {
        maxWidth: `${width}px`,
        maxHeight: `${height}px`,
        left: `${leftShift}px` 
    }

    let cardClass = "card" + (isActive ? " active" : "");
    cardClass += highlighted ? ' highlighted' : '';
    if(interactive){
        cardClass += " interactive";
    }

    return (
        <div className="cardWrapper" style={style}>
            <img alt="card" className={cardClass}  onClick={interactive ? onClick : undefined}
            src={cardImg}/>
        </div>
    )
}

export default Card;
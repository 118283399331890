
const SuitChooser = ({onSelect}) => {

    const suits = [
        {display: '♠', api: 'S', color: 'blackSuit'},
        {display: '♣', api: 'C', color: 'blackSuit'},
        {display: '♦', api: 'D', color: 'redSuit'},
        {display: '♥', api: 'H', color: 'redSuit'},
    ]

    return (
        <div className="wagerPanel suitChooser">
            {suits.map((suit, index) => (
                <div key={index} className={"suitOption "+suit.color} onClick={() => {onSelect(suit.api)}}>
                    <span>{suit.display}</span>
                </div>
            ))}
        </div>
    )
}

export default SuitChooser;
import { getMyPlayer } from "../api/StateUtilities";

const CenterCards = ({state}) => {


    const classesForOrder = ["southCenterCard", "westCenterCard", "northCenterCard", "eastCenterCard"];

    const me = getMyPlayer(state);
    const myOrder = me ? me.order : 0;

    // if(state){
    //     state.center_cards = [
    //         // { card: '0H', player_order: 0, order_played: 0},
    //         { card: '1S', player_order: 1, order_played: 1},
    //         // { card: '0J', player_order: 2, order_played: 2},
    //     ];
    // }

    const sortedCenterCards = state ? state.center_cards.sort((c1, c2) => {
        return c1.order_played - c2.order_played;
    }) : [];

    return (
        <div className="centerCards">
            {
                sortedCenterCards.map((centerCard, index) => {
                    // fix the joker cards
                let image = centerCard.card;
                if(image.charAt(0) === '0' || image.charAt(0) === '1'){
                    image = image.charAt(0) + 'J';
                }
                return (
                    <img 
                        className={classesForOrder[reduce(centerCard.player_order - myOrder, 4)]}
                        src={`/images/cards/${image}.svg`}
                        style={{zIndex: index}}
                        alt={centerCard.card}
                        key={centerCard.player_order}
                    />
                )
                })}
        </div>
    );
}

const reduce = (num, mod) => {
    if(num < 0){
        return mod - (-num % mod);
    } else {
        return num % mod;
    }
}

export default CenterCards;
const DialogMessage = ({message, onAcknowledge, socket}) => {

    let options = [];

    if(message.options){
        message.options.forEach(opt => {
            options.push({
                option: opt.option,
                func: () => {
                    opt.func();
                    onAcknowledge();
                }
            })
        });
    }
    else {
        options.push({option: 'Ok', func: onAcknowledge})
    }

    return (
        <div className="dialogBox">
            <div className="relAnchor">
                <div className="messageTitleBar">
                    <span className="titleText">{message.title}</span>
                </div>
                <div className="bodySection">
                    <span className="bodyText">{message.body}</span>
                </div>
                <div className="buttonSection">
                    {
                        options.map(opt => (
                            <button className="button joinGame" onClick={opt.func}>{opt.option}</button>
                        ))
                    }
                    {/* <button className="button joinGame" onClick={onAcknowledge}>Ok</button> */}
                </div>
            </div>
        </div>
    );
}

export default DialogMessage;